/**
 * @author Sanyasirao
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import ExpandableContent from 'Component/ExpandableContent';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import Loader from 'Component/Loader';
import CartQuery from 'Query/Cart.query';
import { fetchQuery } from 'Util/Request/Query';
import GiftMessage from './GiftMessage.component';
import { CONFIG_VAR } from './../../config/env';

/** @namespace Component/GiftMessage/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Component/GiftMessage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    getGiftMessageFromCart: async () => await CartDispatcher.getGiftMessageFromCart(dispatch),
    applyGiftMessageToCart: (giftMessage) => CartDispatcher.applyGiftMessageToCart(dispatch, giftMessage),
    removeGiftMessageFromCart: () => CartDispatcher.removeGiftMessageFromCart(dispatch),
});

/** @namespace Component/GiftMessage/Container */
export class GiftMessageContainer extends PureComponent {
    static defaultProps = {
        mix: {},
        title: 'Title Gift',
    };

    state = {
        giftMessage: null,
        isLoading: false,
        isIncorrectGiftMessage: false,
    };

    componentDidMount() {
        this.getGiftMessage();
    }

    getGiftMessage() {

        const { isSignedIn = false, getGiftMessageFromCart } = this.props;
        getGiftMessageFromCart().then((messageData) => {
            // console.log("Got message Data on guest: ", messageData);
            this.setState({giftMessage: messageData.getGiftMessage});
        });
    }

    containerFunctions = {
        handleApplyGiftMessageToCart: this.handleApplyGiftMessageToCart.bind(this),
        handleRemoveGiftMessageFromCart: this.handleRemoveGiftMessageFromCart.bind(this),
        resetIsIncorrectGiftMessage: this.resetIsIncorrectGiftMessage.bind(this),
    };

    containerProps() {
        const { isLoading, isIncorrectGiftMessage, giftMessage } = this.state;
        const { mix, title } = this.props;

        return {
            isLoading,
            isIncorrectGiftMessage,
            giftMessage,
            mix,
            title,
        };
    }

    resetIsIncorrectGiftMessage() {
        this.setState({ isIncorrectGiftMessage: false });
    }

    handleApplyGiftMessageToCart(giftMessage) {
        const { applyGiftMessageToCart } = this.props;

        this.setState({ isLoading: true });
        applyGiftMessageToCart(giftMessage).then(
            /** @namespace Component/GiftMessage/Container/GiftMessageContainer/handleApplyGiftMessageToCart/then/finally/applyGiftMessageToCart/then */
            (success) => {
                this.setState({ 
                    isIncorrectGiftMessage: !success,
                    giftMessage: giftMessage,
                 });

            },
        ).finally(
            /** @namespace Component/GiftMessage/Container/GiftMessageContainer/handleApplyGiftMessageToCart/then/finally */
            () => this.setState({ isLoading: false }),
        );
    }

    handleRemoveGiftMessageFromCart() {
        const { removeGiftMessageFromCart } = this.props;

        this.setState({ isLoading: true });

        removeGiftMessageFromCart().then(
            /** @namespace Component/GiftMessage/Container/GiftMessageContainer/handleRemoveGiftMessageFromCart/removeGiftMessageFromCart/then */
            () => this.setState({ 
                isLoading: false,
                giftMessage: null,
             }),
        );
    }

    render() {

        if (!CONFIG_VAR.USE_GIFT_MESSAGE) {
            return null;
        }
        
        const { giftMessage } = this.state;

        return (
            <ExpandableContent
                heading={ (!giftMessage) ? __('Want to add Gift Message?') : __('Added Gift Message')}
                mix={ { block: 'OnepageCheckout', elem: 'GiftMessage' } }
                isArrow
            >
                <GiftMessage
                { ...this.containerProps() }
                { ...this.containerFunctions }
                />
            </ExpandableContent>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftMessageContainer);
