// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './TamaraDetails.style';
import StatusComponent from 'Component/Status/Status.component';
import CheckoutFailure from 'Component/CheckoutFailure';

/** @namespace AlmusbahblendPwa/Component/TamaraDetails/Component */
export class TamaraDetailsComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerProps() {
        
        const { success, result, orderId, orderNumber, isLoading, email, firstname, lastname  } = this.props;
        
        const orderID = orderNumber;
        let isEmailAvailable = false;

        if (email != '') {
            isEmailAvailable = true;
        }

        let title = __('Payment Failure');
        let CheckoutStep = 'PAYMENT_FAIL';
        if (success) {
            title = __('Thank you for your purchase!');
            CheckoutStep = 'DETAILS_STEP';
        }
        
        return {
            result,
            isLoading,
            orderID,
            email,
            isEmailAvailable,
            firstname,
            lastname,
            success,
            title,
            CheckoutStep,
        };
    }

    render() {
        if (this.props.success) {
            return (
                <StatusComponent {...this.containerProps()} />
            );
        } else {
            return (
                <CheckoutFailure {...this.containerProps()} />
            )
            
        }
    }
}

export default TamaraDetailsComponent;
