import {
    MyAccountAddressFormComponent as SourceMyAccountAddressFormComponent,
} from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.component';

import myAccountAddressForm from './MyAccountAddressForm.form';
import { trimCustomerAddress } from 'Util/Address';
import transformToNameValuePair from 'Util/Form/Transform';
import './MyAccountAddressForm.override.style';

/** @namespace AlmusbahblendPwa/Component/MyAccountAddressForm/Component */
export class MyAccountAddressFormComponent extends SourceMyAccountAddressFormComponent {
    // TODO implement logic

    //#region GETTERS
    fieldMap() {
        const {
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            availableCities,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            currentTelephone,
            currentTeleExtCode,
            onCityChange,
            onRegionChange,
            onRegionIdChange,
            onTelephoneChange,
            onCountryChange,
            onZipcodeChange,
        } = this.props;

        // console.log("fieldMap availableCities: ", availableCities);

        return myAccountAddressForm({
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            availableCities,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            currentTelephone,
            currentTeleExtCode,
            ...address,
        }, {
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange,
            onTelephoneChange,
        });
    }

    /**
     * Creates / Updates address from entered data
     * @param form
     * @param fields
     */
    onSubmit(form, fields) {
        const { onSave, addressLinesQty } = this.props;
        const newAddress = transformToNameValuePair(fields);

        // Joins streets into one variable
        if (addressLinesQty > 1) {
            newAddress.street = [];
            // eslint-disable-next-line fp/no-loops,fp/no-let
            for (let i = 0; i < addressLinesQty; i++) {
                const streetKey = `street_${i}`;

                if (streetKey in newAddress) {
                    newAddress.street.push(newAddress[streetKey]);
                }
            }
        }

        // Fixes region variable format
        const { region_id = 0, region_string: region = '', tele_ext_code = '', telephone = '' } = newAddress;

        // newAddress.telephone =  tele_ext_code.replace(/^\++|\++$/gm,'') + telephone;
        let extCode = tele_ext_code.split("+");
        newAddress.telephone = extCode[1] + telephone;
        newAddress.region = { region_id: +region_id, region, region_code: region };

        // Filters out non-required options and save address
        onSave(trimCustomerAddress(newAddress));
    }
}

export default MyAccountAddressFormComponent;
