/**
 * @author - Sanyasi Rao
 */

import { CustomerAddress } from 'Query/MyAccount.type';
import { OrderAddress } from 'Query/Order.type';
import { Country, Region } from 'Query/Region.type';
import { Store } from 'Query/StoreInPickUp.type';
import { CheckoutAddress } from 'Route/Checkout/Checkout.type';
import { GQLCountryCodeEnum, GQLCustomerAddressInput } from 'Type/Graphql.type';
import { getStoreState } from 'Util/Store';

import { CountryOption, FormattedRegion, ZippopotamResponseResult } from './Address.type';


/** @namespace Util/Address/Index/getAvailableRegions */
export const getSACitiesList = (country_id, store_code) => {
    let cities = [];
    // if (country_id == 'SA') {
    //     cities = getSACities(store_code);
    // }

    // const country = countries.find(({ id }) => id === country_id);
    // const { available_regions } = country || {};

    // need to handle null value
    return cities;
};

export const getSACities = (store_code) => {

    const enCities = [
        {name:__('Month')},
        {name:__('Hours')},
        {name:__('sssss')},
        {name:__('fffff')}
    ];

    return enCities;

}

export default (getSACitiesList, getSACities);
