import {
    CategoryDispatcher as SourceCategoryDispatcher,
} from 'SourceStore/Category/Category.dispatcher';
import CategoryQuery from 'Query/Category.query';
import { updateCurrentCategory } from 'Store/Category/Category.action';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import agtm from '../../gtm/tags';

export class CategoryDispatcher extends SourceCategoryDispatcher {
    // TODO implement logic
    dataLayer = {};

    onSuccess(
        data,
        dispatch,
        { isSearchPage },
    ) {
        const {
            category,
            category: { id },
        } = data;
        
        if (!id && !isSearchPage) {
            dispatch(updateNoMatch(true));
        }
        // Call GTM for Category View
        this.gtmCategoryView(category);
        
        dispatch(updateCurrentCategory(category));
    }

    gtmCategoryView(category) {
        this.dataLayer['Category Id'] = category.id;
        this.dataLayer['Category Name'] = category.name;
        this.dataLayer['Item Count'] = category.product_count;

        this.dataLayer['Sort'] = category.default_sort_by;

        this.dataLayer['Pagination'] = 1;
        this.dataLayer['Page Type'] = category.display_mode;
        this.dataLayer['URL'] = category.url;

        const date_time = `${Math.floor(new Date() / 1000)}`
        let contentCategory = category.name
        let contentName = category.name
        let contentType = 'product_group'
        let contentIds = [category.id]
        let contents = [{id: category.id, items_count: category.product_count}]
        // for(let i in category.items){
        //     contentIds.push(category.items[i]._id)
        //     contents.push({id:category.items[i]._id , quantity:category.items[i].stock.quantity?category.items[i].stock.quantity:1})
        // }
        agtm.triggerCategoryView(this.dataLayer, [], category.name, 'Listing Page', category.url_path, {date_time, contentIds, contents, contentType,  contentName, contentCategory});
    }
};

export default new CategoryDispatcher();
