/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Status from './Status.component';
import CheckoutFailure from 'Component/CheckoutFailure';
import Loader from 'Component/Loader';
import OrderQuery from 'Query/Order.query';
import { fetchQuery } from 'Util/Request/Query';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import agtm from '../../gtm/tags';
import { appendWithStoreCode } from 'Util/Url';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/Config';
import { CART_URL } from 'Route/CartPage/CartPage.config';

import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace Component/Status/Container/StatusContainer */
export class StatusContainer extends PureComponent {

    static propTypes = {
        method: PropTypes.string.isRequired,
        checkoutId: PropTypes.string.isRequired,
        resoursePath: PropTypes.string.isRequired
    };

    state = {
        success: false,
        result: false,
        isLoading: true,
        orderID: '',
        email: '',
        firstname: '',
        lastname: ''
    };

    componentDidMount() {

        const {
            match: {
                params: {
                    orderId,
                    orderStatus = 'failure',
                },
            },
        } = this.props;

        const prevOrderId = BrowserDatabase.getItem('OREDR_NUMBER') || undefined;
        console.log("Previous orderId: ", prevOrderId);
        console.log("current orderId: ", orderId);
        if (prevOrderId == undefined || prevOrderId != orderId) {
            BrowserDatabase.setItem(
                orderId,
                'OREDR_NUMBER',
                ONE_MONTH_IN_SECONDS,
            );
        } else {
            window.location.replace(appendWithStoreCode(CART_URL));
            return null;
        }

        this.requestOrderData(orderId, orderStatus);
        // const {
        //     method,
        //     isLoading,
        //     checkoutId,
        //     resoursePath,
        // } = this.props;
        // this.requestPaymentStatus(checkoutId, method);
        
    }

    async requestOrderData(orderId, orderStatus) {
        let status = false;
        if (orderStatus == 'success') {
            status = true;
        }

        const orderDataRes = await fetchQuery(OrderQuery.getOrderData(orderId));
        const orderData = orderDataRes.getorder;
        // console.log("Order orderData", orderData);
        if(orderData && orderData.id) {
            this.setState({
                isLoading: false,
                email: orderData.email || '',
                orderID: orderData.increment_id,
                result: true,
                success: status,
                firstname: orderData.address.firstname || '',
                lastname: orderData.address.lastname || '',
            });

            if(status) {
                for(let i in orderData.items){
                    orderData.items[i]['prices'] = {
                        regular_price: {value: orderData.items[i].regular_price},
                        price: {value: orderData.items[i].price},
                        final_price: {value: orderData.items[i].final_price},
                        total_item_discount: orderData.items[i].total_item_discount,
                    }
                }
                let gtmObj = {
                    products:orderData.items,
                    order_id:orderData.increment_id,
                    transaction_id:orderData.increment_id,
                    summary: {
                        coupon_code: orderData.coupon_code,
                        applied_rule_ids: orderData.applied_rule_ids,
                        discount: { amount: { value: orderData.discount}, percent: {value: 0}},
                        subtotal_including_tax: {value: orderData.subtotal_including_tax},
                        applied_taxes: [{amount: {value: orderData.tax_amount}}],
                        shipping_amount_incl_tax: {value: orderData.shipping_incl_tax},
                        cod_fee_incl_tax: {value: orderData.cod_fee_incl_tax},
                    },
                    shipping:orderData.address,
                    payment: orderData.payment_method,
                    shipping_method: orderData.shipping_method,
                }
                const event = (status) ? 'completed' : 'failed';
                var date_time = `${Math.floor(new Date() / 1000)}`
                let contentCategory = 'Purchase'
                let contentName = 'Purchase'
                let contentIds = []
                let contents = []
                for(let i in orderData.items){
                    contentIds.push(orderData.items[i].sku)
                    contents.push({id:orderData.items[i].sku , quantity:orderData.items[i].salable_qty?orderData.items[i].salable_qty:1})
                }
                let num_items = contentIds.length
                gtmObj['customerInfo'] = orderData.address || {};
                agtm.checkoutEvent(date_time, gtmObj, event, 'OrderConfirmationPage', 'en' ,{date_time ,contentIds ,contents , num_items ,contentName , contentCategory});
            }
        } else {
            this.setState({
                isLoading: false,
                result: true,
                success: false,
            });
        }
    }

    containerProps() {
        
        const { success, result, orderID, isLoading, email, firstname, lastname  } = this.state;
        
        let isEmailAvailable = false;

        if (email != '' && !isSignedIn()) {
            isEmailAvailable = true;
        }

        let title = __('Payment Failure');
        let CheckoutStep = 'PAYMENT_FAIL';
        if (success) {
            title = __('Thank you for your purchase!');
            CheckoutStep = 'DETAILS_STEP';
        }
        
        return {
            result,
            isLoading,
            orderID,
            email,
            isEmailAvailable,
            firstname,
            lastname,
            success,
            title,
            CheckoutStep,
        };
    }

    render() {
        const { isLoading } = this.state;
        // const params = window.query.params();
        // console.log("==== Query params ======", params)
        if (this.state.result) {
            if (this.state.success) {
                return (
                    <Status {...this.containerProps()} />
                );
            } else {
                return (
                    <CheckoutFailure {...this.containerProps()} />
                );
            }
            

        } else {
            return (
                <main block="Checkout" elem="FullPageLoader">
                    <Loader isLoading />
                </main>
            );
        }
    }
}

export default StatusContainer;
