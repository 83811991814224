/**
 * @author - Sanyasirao
 */

import { Field } from 'Util/Query';

/**
 * Slider Query
 * @class Slider
 * @namespace Query/TabbyQuery */
export class TabbyQuery {
    getTabbyPaymentTypes(countryCode, currencyCode, orderValue, phoneNumber) {
        // console.log("received orderValue for Tabby options: ", orderValue);
        return new Field('getpaymentoptions')
        .addArgument('countryCode', 'String', countryCode)
        .addArgument('currencyCode', 'String', currencyCode)
        .addArgument('orderValue', 'String', orderValue)
        .addArgument('phoneNumber', 'String', phoneNumber)
        .addFieldList(this._getOrderFields());
    }

    _getOrderFields() {
        return [
            'method',
            'paymentValue',
            'no_of_installments',
            'first_installment_value',
        ];
    }

    getTabbyPaymentUrl(orderId) {
        // console.log("received orderId for placetamaraorder: ", orderId);
        return new Field('placetamaraorder')
        .addArgument('orderId', 'String', orderId)
        .addFieldList(this._getTabbyUrlFields());
    }

    _getTabbyUrlFields() {
        return [
            'success',
            'orderId',
            'redirectUrl',
            'error',
        ];
    }

    setPaymentMethodAndPlaceOrderMutation(input) {
        // console.log("setPaymentMethodAndPlaceOrderMutation input: ", input);
        return new Field('setPaymentMethodAndPlaceOrderForTabby')
        .addArgument('input', 'SetPaymentMethodAndPlaceOrderInputTabby', input)
        .addField(this._getOrderField());
    }

    _getOrderField() {
        return new Field('order')
            .addFieldList(['order_id', 'order_number', 'redirectUrl']);
    }

    updateTabbyPaymentStatus(input) {
        // console.log("updateTabbyPaymentStatus input: ", input);
        return new Field('tabbypaymentresponse')
        .addArgument('input', 'TabbyPaymentInput', input)
        .addFieldList(this._getTabbyOrderField());
    }

    _getTabbyOrderField() {
        return [
            'orderNumber',
            'orderId',
            'status',
            'message',
            'email',
            'firstname',
            'lastname'
        ];
    }

    getTabbyPaymentConfig() {
        return new Field('gettamarapaymentconfig')
        .addArgument('countryCode', 'String', 'SA')
        .addFieldList(this._getTabbyConfigData());
    }

    _getTabbyConfigData() {
        return [
            'tamaraLocale',
            'tamaraCurrency',
            'tamaraCountry',
            'tamaraPublicKey',
            'tamaraProductWidgetUrl',
            'tamaraInstalmentWidgetUrl',
            'codOtpEnable'
        ];
    }

    getProductBaseCurrencyValue(id) {
        return new Field('getbasecurrencyvalue')
        .addArgument('product_id', 'Int', id)
        .addFieldList(this._getBaseCurrencyPriceData());
    }

    getGrandTotalBaseCurrencyValue(cartId) {
        return new Field('getbasecurrencyvalue')
        .addArgument('cart_id', 'String', cartId)
        .addFieldList(this._getBaseCurrencyPriceData());
    }

    _getBaseCurrencyPriceData() {
        return [
            'final_base_currency_value'
        ];
    }

    checkTabbyPreScoring(preScoringInput) {
        console.log("received preScoringInput: ", preScoringInput);
        return new Field('checktabbyprescoring')
        .addArgument('input', 'TabbyPreScoreInput', preScoringInput)
        .addFieldList(this._getPreScoringResponseData());
    }

    _getPreScoringResponseData() {
        return [
            'success',
            'message'
        ];
    }
}

export default new TabbyQuery();
