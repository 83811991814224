import { connect } from 'react-redux';

import {
    CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.container';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace AlmusbahblendPwa/Component/CheckoutPayments/Container */
export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {
    // TODO implement logic
    containerProps() {
        const {
            paymentMethods,
            setOrderButtonEnableStatus,
            showError,
            selectedPaymentMethod : selectedPaymentCode = '',
            totals,
            email,
            currentDeliveryAddress,
            tabbyErrorMessage,
            isTabbyDisabled,
        } = this.props;

        // const { selectedPaymentCode } = this.state;

        return {
            paymentMethods,
            selectedPaymentCode,
            setOrderButtonEnableStatus,
            showError,
            totals,
            email,
            currentDeliveryAddress,
            tabbyErrorMessage,
            isTabbyDisabled,
        };
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
