import {
    CheckoutPaymentComponent as SourceCheckoutPaymentComponent,
} from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import { HYPERPAY_APPLEPAY, HYPERPAY_CARDS, HYPERPAY_MADA, TAMARA_PAYMENT, TABBY_PAYMENT } from 'Route/OnepageCheckout/OnepageCheckout.component';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import { getStoreState } from 'Util/Store';
import './CheckoutPayment.override.style';

/** @namespace AlmusbahblendPwa/Component/CheckoutPayment/Component */
export class CheckoutPaymentComponent extends SourceCheckoutPaymentComponent {
    // TODO implement logic

    state = {
        selectedMethod: '',
        tabbyErrorMessage: '',
    };

    showImage() {
        const {
            method,
        } = this.props;

        const { ConfigReducer: { base_link_url = window.location.href, base_url = window.location.href } = {} } = getStoreState();
        if (method.code == HYPERPAY_CARDS) {
            let imageurl = base_url + 'static/frontend/scandipwa/almusbahblend-pwa/en_US/Hyperpay_Extension/images/cards.jpg';
            return(
                <>
                <img src={imageurl}  class="Cards_img"></img>
                </>
            );
        } else if (method.code == HYPERPAY_APPLEPAY) {
            let imageurl = base_url + 'static/frontend/scandipwa/almusbahblend-pwa/en_US/Hyperpay_Extension/images/apple.svg';
            return(
                <>
                <img src={imageurl} class="Apple_img"></img>
                </>
            );
        } else if (method.code == HYPERPAY_MADA) {
            let imageurl = base_url + 'static/frontend/scandipwa/almusbahblend-pwa/en_US/Hyperpay_Extension/images/mada.svg';
            return(
                <>
                <img src={imageurl}  class="Mada_img"></img>
                </>
            );
        } else if (method.code.includes(TAMARA_PAYMENT)) {
            let imageurl = 'https://cdn.tamara.co/assets/svg/tamara-logo-badge-en.svg';
            return(
                <>
                <img src={imageurl}  class="Tamara_img"></img>
                </>
            );
        } else if (method.code == TABBY_PAYMENT) {
            let imageurl = base_url + 'static/frontend/scandipwa/almusbahblend-pwa/en_US/Tabby_Checkout/images/logo_black.png';
            return(
                <>
                <img src={imageurl}  class="Tabby_img"></img>
                </>
            );
        } else {
            return null;
        }
        
    }

    renderTabbyPaymentWidget() {
        const {
            method: { code, title },
            isTabbyDisabled,
        } = this.props;

        const {
            tabbyErrorMessage,
        } = this.state;

        if (code == TABBY_PAYMENT) {
            if(!isTabbyDisabled && document.getElementById(code)) {
                document.getElementById(code).disabled = false;
            }
            
            return (
                <>
                    <div block="TabbyBlock" elem="ErrorMessage">
                        <p>{__(tabbyErrorMessage)}</p>
                    </div>
                </>
            );
        } else {
            return null;
        }

    }

    renderTabbyPaymentErrorMessage() {
        const {
            selectedMethod,
        } = this.state;

        const {
            method: { code, title },
            isTabbyDisabled,
            tabbyErrorMessage,
        } = this.props;

        this.setState({
            tabbyErrorMessage: tabbyErrorMessage,
        });

        if(selectedMethod == TABBY_PAYMENT) {
            if (isTabbyDisabled) {
                
                document.getElementById(code).disabled = true;
                if(document.getElementById("TabbyCard")) {
                    document.getElementById("TabbyCard").style.display = "none";
                }

                this.unSetPaymentMethod();
            }
            return (
                <>
                    <div id="TabbyCard"></div>
                </>
            );
        } else {
            return null;
        }
    }

    unSetPaymentMethod() {
        const {
            onClick,
            method,
        } = this.props;

        this.setState({
            selectedMethod: HYPERPAY_CARDS,
        });

        onClick(HYPERPAY_CARDS);
    }

    onClick() {
        const {
            onClick,
            method,
        } = this.props;

        this.setState({
            selectedMethod: method.code,
        });

        onClick(method);
        
    }

    render() {
        const {
            isSelected,
            method: { code, title },
            isDisabled,
            paymentMethodErrorMsg,
        } = this.props;

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                  id={ code }
                >
                    <Field
                      type={ FieldType.CHECKBOX }
                      attr={ {
                          id: `option-${ title }`,
                          name: `option-${ title }`,
                          checked: isSelected,
                      } }
                      label={ __(title) }
                      isDisabled={ isDisabled }
                    />
                    { this.showImage() }
                </button>
                { this.renderTabbyPaymentWidget() }
                { this.renderTabbyPaymentErrorMessage() }
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
